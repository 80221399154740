//pricing.jsx
import React from "react";

import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatedCard } from "./Utils";
import {
  faCheckCircle,
  faCalendarAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const Pricing = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Adjust as needed
  });
  return (
    <div id="pricing" className=" main-section centered  bg-backroundColor">
      <div className="flex-no-wrap  dir-col  ">
        <div>
          <h2
            ref={ref}
            className={` display-heading flex   align-center justify-center ${
              inView ? "animate" : ""
            }`}
          >
            Reduce your AML and Screnning Costs
          </h2>

          <h4
            ref={ref}
            className={` flex header justify-center align-center  b-margin3 ${
              inView ? "animate" : ""
            }`}
          >
            Start with a base subscription of USD $99/month for up to 100 searches.
            Additional searches are priced based on usage.
          </h4>

          

          
        </div>

        <IncludedFeatures></IncludedFeatures>
        <h4 
           
            className=" flex header t-margin3"
          >
            You can choose your billing option:
           
          </h4>
          <h4 
            
            className=" flex header b-margin3"
          >
            <ul>
            <li> <h6 > Pay directly with your preferred payment method. </h6></li>
            <li> <h6 > Consolidate billing and invoicing through your AWS account. </h6></li>
            
            </ul>
          </h4>
      </div>
    </div>
  );
};

export default Pricing;



export const IconText = ({ icon, text }) => {
  return (
    <div className="flex-no-wrap align-center">
      <h5 className="no-margin">
        <FontAwesomeIcon className="iconDefault" icon={icon} />
      </h5>
      <h5 className="no-margin">{text}</h5>
    </div>
  );
};

export const IncludedFeatures = () => {
  return (
    <div className=" grid-container-price-inc">
      <AnimatedCard bgClass="grid-item-price-inc card-feature  focus-xpirience ">
        <h2 className="no-margin">Starting at $99/month</h2>
      </AnimatedCard>

      <AnimatedCard bgClass="grid-item-price-inc card-feature focus-bg focus-xpirience">
        <IconText icon={faCheckCircle} text="Access to all features" />
      </AnimatedCard>
      <AnimatedCard bgClass=" grid-item-price-inc card-feature focus-bg focus-xpirience">
        <IconText icon={faCalendarAlt} text="Cancel anytime" />
      </AnimatedCard>

      <AnimatedCard bgClass=" grid-item-price-inc card-feature focus-bg focus-xpirience">
        <IconText
          icon={faCheckCircle}
          text="100 searches
          per month included"
        />
      </AnimatedCard>

      <AnimatedCard bgClass="grid-item-price-tier tier-price-card ">
        <p className="text6 no-margin">
          <FontAwesomeIcon className="iconDefault" icon={faSearch} /> From 100 searches,
          $0,10/search.
        </p>
      </AnimatedCard>
      <AnimatedCard bgClass=" grid-item-price-tier tier-price-card  ">
        <p className="text6 no-margin">
          <FontAwesomeIcon className="iconDefault" icon={faSearch} /> From 1000 searches,
          $0,08/search.
          </p>
      </AnimatedCard>
      <AnimatedCard bgClass=" grid-item-price-tier tier-price-card ">
      <p className="text6 no-margin">
          <FontAwesomeIcon className="iconDefault" icon={faSearch} /> From 5000 searches,
          $0,05/search.
          </p>
      </AnimatedCard>
      <AnimatedCard bgClass=" grid-item-price-tier tier-price-card ">
      <p className="text6 no-margin">
          <FontAwesomeIcon className="iconDefault" icon={faSearch} /> Fom 10.000 searches,
          $0,03/search.
          </p>
      </AnimatedCard>
    </div>
  );
};

