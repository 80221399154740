//landingPage
import React from "react";

import AppLayoutLogin from "../components/AppLayoutLogin";
import { Helmet } from "react-helmet";

import Footer from "../components/Footer";
import customerView from "../images/customerView.webp";

import { FAQ } from "../components/Utils";

import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";


const headerCustomerOngoingMonitoring = {
    h1:true,
    title: "Automated Customer Ongoing Monitoring",
    description: `Automate the ongoing monitoring and continuous screening of customers to maintain constant compliance. `,
  };

  const featuresCustomerOngoingMonitoring = [
    {
        title: "Automated Daily Ongoing Monitoring",
        description: `Plan and automate daily monitoring processes. View customers who appear on sanctions lists directly in the dashboard, ensuring efficient compliance management.`,
      },

      {
        title: "Reduce False Positives",
        description: `Add results to the allowlist (safe list) to avoid receiving the same alert for the same customer in future checks, streamlining your monitoring process.`,
      },
  
      {
        title: "Effortless Compliance Audit Tracking",
        description: `Keep track of compliance with automated reports that log all monitoring activities. 
        Reduce manual effort by automating customer rescreening and reporting in one place.`,
      }
    
  ];
  
  const faqData = [
    { question: "What is automated daily ongoing monitoring, and how can it help with compliance", 
      answer: `Automated daily monitoring in Checklynx scans
       your customer database against sanctions lists (at least) every day. Any matches are flagged and displayed in your dashboard, ensuring real-time compliance management.` },
    
    { question: "How does automated customer ongoing monitoring work in Checklynx", 
      answer: `Checklynx continuously monitors your customers against global sanctions lists, automating the screening process to ensure you're always compliant. This helps you stay updated with minimal manual effort` },
    

    { question: "How does Checklynx reduce false positives in customer monitoring?", 
      answer: `Checklynx reduces false positives by allowing you to add customers to an allowlist (safe list).
       This prevents redundant alerts for the same customer during future checks, streamlining your monitoring process.` },
    
    { question: "What is the allowlist feature, and how does it streamline the monitoring process?", 
        answer: `The allowlist feature in Checklynx lets you mark certain results as safe,
         ensuring that the system won't alert you for the same customer repeatedly, which reduces unnecessary notifications and false positives.` },

  { question: "How does Checklynx help with compliance audit tracking?", 
          answer: `Checklynx automates audit tracking by logging all monitoring activities and any changes, such as editing customer attributes, while also generating compliance reports. 
          This ensures you have a complete, easily accessible record of all rescreening, monitoring actions, and modifications for thorough compliance management` },
      
    
 
  ];
  

const CustomerOngoingMonitoringPage = () => {


  return (
    <AppLayoutLogin>
       <Helmet>
  <link rel="canonical" href="https://checklynx.com/solutions/aml-customer-ongoing-monitoring/" />
  <title>How to Continuously Monitor Customers for Enhanced Due Diligence | Checklynx</title>
  <meta name="description" content="Ensure compliance with real-time customer monitoring for AML and KYC. Checklynx provides continuous due diligence, automating the detection of sanctions, robust ongoing monitoring." />


</Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section max-width16">
        <MainEpicContainer
          header={headerCustomerOngoingMonitoring}
          features={featuresCustomerOngoingMonitoring}
          graphic={customerView}
          grapchicAlt="Dashboard displaying daily sanctions checks for customers in compliance monitoring"
        />
      </div>

      <div className="main-section dir-col max-width12">
        <FAQ faqData={faqData}></FAQ>
        </div>


      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default CustomerOngoingMonitoringPage;


