import React from "react";

import { MainEpicContainer } from "../components/MainEpicContainer";

const headerData = {
  title: "Access Global Sanctions & PEP Data for Real-Time Compliance",
  description: `Continues data updates using automatized processes and cutting-edge algorithms.`,
};

const featuresData = [
  {
   
    title: "Always up-to-date",
    description: `Real time identification of sanctioned individuals before official notifications.`,
  },
  {
   
    title: "Local lists",
    description: `Use your own local lists in addition to public ones.`,
  },
  {
    title: "Full coverage",
    description: `Comprehensive coverage of global sanctions lists and Politically Exposed Persons (PEP)  across EMEA, the Americas, and APAC`,
  },
  {
    title: "Sanctions lists include, but are not limited to",
    description: `
  - **INTERNATIONAL: United Nations Sanctions (UN), World Bank
  - **EMEA: European Union (EU), United Kingdom (HM Treasury), France, Netherlands, Switzerland, Israel
  - **AMERICAS: US Consolidated Sanctions, OFAC SDN, Canada Financial Institutions, Brazil, Argentina, Inter-American Development Bank
  - **AFRICA: South Africa, African Development Bank
  - **APAC: Australian Sanctions, Asian Development Bank, Japan`,
  },
  
];

const Databases = () => {

  return (

    <div className="main-section bg-backroundColor">
      <div className=" max-width14 ">
    <MainEpicContainer
      header={headerData}
      features={featuresData}
     
    />
    </div>
    </div>
  );
};

export default Databases;
