import React from "react";
import { Layout } from "antd";
import { width } from "@fortawesome/free-regular-svg-icons/faAddressBook";



const {  Content } = Layout;

const AppLayoutLogin = ({ children }) => {
  

  return (
    
    <div style={layoutStyle}>
   
     
      <div style={contentStyle}>{children}</div>
    </div>
  );
};

export default AppLayoutLogin;

const layoutStyle = {
  padding: "0rem", // Padding as needed
 
 
  display: "flex",
  height: `calc(100vh)`, // Subtract header height from total view height
  flexDirection: "column",
  backgroundColor: "transparent", 
 
};

const contentStyle = {
  
 
  flexDirection: "column",
  display: "flex",
};



