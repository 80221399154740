import React, { useState, useEffect } from "react";

export const GradientContainer = ({ minHeight = "150px", transformClass = "default-transform", children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  // Delaying the gradient load to ensure content renders first
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);  // Apply gradient effect after 400ms
    }, 100);

    return () => clearTimeout(timer);  // Clean up the timeout
  }, []);

  const gradientStyle = {
    minHeight: minHeight,
   
  };

  return (
    <div className={`gradient-container ${isLoaded ? "isLoaded" : ""} ${transformClass}`} style={gradientStyle}>
      {children}
    </div>
  );
};