import React  from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import  {ThemeProvider}  from './contexts/ThemeContext';

import LandingPage from './pages/LandingPage';
import CustomerScreeningPage from './pages/CustomerScreeningPage';
import CustomerOngoingMonitoring from './pages/CustomerOngoingMonitoring';
import PrivacyNotice from './pages/PrivacyNotice';
import Terms from './pages/Terms';
import ContactUs from './pages/ContactUs';
import PEPscreeningPage from './pages/PEPScreeningPage';
import CryptoAssetCompliancePage from './pages/CryptoAssetCompliancePage';
import MaritimeAviationScreeningPage from "./pages/MaritimeAviationCompliancePage"
import CaseManagementPage from "./pages/CaseManagementPage"
import AuditReportingPage from "./pages/AuditReportingPage"



// App Component
function App() {
  return (
   
       <ThemeProvider>
      <Router>
        <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/solutions/aml-sanctions-screening" element={<CustomerScreeningPage />} />
        <Route path="/solutions/aml-customer-ongoing-monitoring" element={<CustomerOngoingMonitoring />} />
        <Route path="/solutions/aml-pep-screening" element={<PEPscreeningPage />} />
        <Route path="/solutions/aml-crypto-assets-screening" element={<CryptoAssetCompliancePage />} />
        <Route path="/solutions/aml-maritime-aviation-screening" element={<MaritimeAviationScreeningPage />} />
        <Route path="/solutions/aml-case-management" element={<CaseManagementPage />} />
        <Route path="/solutions/aml-customer-risk-management" element={<PEPscreeningPage />} />
        <Route path="/solutions/aml-audit-reporting" element={<AuditReportingPage />} />
        <Route path="/privacy" element={<PrivacyNotice />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/pricing" element={<LandingPage scrollTo="pricing" />} />
        <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </Router>
      </ThemeProvider>
      
      
  );
}

export default App;