//landingPage
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";

import AppLayoutLogin from "../components/AppLayoutLogin";
import HeroSection from "../components/HeroSection";
import Databases from "../components/Databases";
import Integration from "../components/Integration";
import DataExpirience from "../components/DataExpirience";
import SearchExperience from "../components/SearchExperience";
import Pricing from "../components/Pricing";
import { ColoredCards } from "../components/Cards";
import Footer from "../components/Footer";
import FindUsIn from "../components/FindUsIn";

import { HeaderComp } from "../components/HeaderComp";
import SolutionSection from "../components/SolutionSection";
import { GradientContainer } from "../components/Gradients";
import { DynamicImage } from "../components/DynamicImage";
import WhyChecklynx from "../components/WhyChecklynx"

const LandingPage = ({ scrollTo }) => {
  
  const [showIcons, setShowIcons] = useState(false);

  const scrollThresholdIcons = 490; // Set the scroll position in pixels (e.g., 1000px)

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled past the threshold
     
      if (window.scrollY > scrollThresholdIcons) {
        setShowIcons(true);
      } else {
        setShowIcons(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollTo) {
      const section = document.getElementById(scrollTo);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [scrollTo]);

  return (

    <AppLayoutLogin>
       <Helmet>
        <link rel="canonical" href="https://checklynx.com/" />
        <title>Checklynx - Real-Time Compliance Monitoring and AML Customer Screening</title>
        <meta name="description" content="Automate sanctions screening and PEP compliance. Reduce false positives and enhance AML compliance with Checklynx for $99/month." />
       
      </Helmet>
      {/* HeroSection */}
      <div>
      
        <GradientContainer minHeight="940px" transformClass="alt-transform">
          <HeaderComp />
        
          <HeroSection />
          
          </GradientContainer>
        
          
       
        
          
          <DynamicImage showIconsAndLines={showIcons} />

          <div style={{ height: "300px" }}></div>
        


        <FindUsIn />
       
        <WhyChecklynx></WhyChecklynx>
       
        
        <ColoredCards />
            
            <SolutionSection />
           
           
            <Databases />
            
        
      </div>

      <SearchExperience />
     <DataExpirience />
     

  <Integration />
      <Pricing />
      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default LandingPage;
