import React from "react";
import { Button } from "antd";
import con from "../config.json";
import "./Footer.css";


const Footer = ({ showContactCard }) => {
  return (
    <div className="align-center main-section-no-padding dir-col">
      {showContactCard && <ContactCard />}
      <footer className={`footer ${showContactCard ? 'extra-top-padding' : ''}`}>

        <div className="footer-container">
          <div className="footer-section">
          <h2 id="footer-heading" className="visually-hidden">Footer</h2>
          <nav aria-label="Company">
          <h3>Company</h3>
            <ul>
              <li>
                <a href="/privacy">Privacy</a>
              </li>
              <li>
                <a href="/terms">Terms and Conditions</a>
              </li>

              <li>


                <a href="/contact">Contact Us</a>
                
              </li>
            </ul>
            </nav>
          </div>
          <div className="footer-section">
          <nav aria-label="Solutions">
            <h3>Solutions</h3>
            <ul>
              <li>
                <a href="/solutions/aml-sanctions-screening">Sanctions screening</a>
              </li>
              <li>
                <a href=" /solutions/aml-pep-screening">PEP screening</a>
              </li>
              <li>
                <a href="/solutions/aml-customer-ongoing-monitoring">Customer monitoring</a>
              </li>


             
             
            </ul>
            </nav>
          </div>
          
          <div className="footer-section">
          <nav aria-label="Resources">
            <h3>Resources</h3>
            <ul>
              <li>
                <a
                  href={con.urls.checklynx_app}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Checklynx App
                </a>
              </li>
              <li>
                <a
                  href={con.urls.dev_portal}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Developer Portal
                </a>
              </li>
              <li>
                <a
                  href={con.urls.aws_marketplace_product}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AWS marketplace
                </a>
              </li>
              <li>
                <a
                  href={con.urls.stripe_marketplace_product}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stripe marketplace
                </a>
              </li>
            </ul>
            </nav>
          </div>
        </div>
        <div className="footer-bottom">
        <h6> @2024 Checklynx. All rights reserved.</h6>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

const ContactCard = () => {
  return (
    <div className="contact-card">
      <div className="text-center b-padding2 t-padding2 side-padding2">
        <h3>Start your journey</h3>
        <h5>Don’t risk your AML compliance</h5>
      </div>
      <div className="text-center buttons-grid b-padding2 t-padding2 side-padding2">
      <a href={con.calendly.url} target="_blank" rel="noopener noreferrer">
        <Button className="w240 extra-height1 text-button-big" size="large" type="default" ghost={true}>
          Contact an expert
        </Button>
      </a>
      <a href={con.urls.checklynx_app_sign_up} target="_blank" rel="noopener noreferrer">
        <Button aria-label="Start for Free and Try 30 Days" className="w240 extra-height1 text-button-big bg-white" size="large" type="primary" ghost={true} >
        Try 30 days Free
        </Button>
      </a>
      </div>
     
    </div>
  );
};
