//landingPage
import React from "react";
import { Helmet } from "react-helmet";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";
import PEPexample1 from "../images/PEPexamplePart1.webp";
import PEPexample2 from "../images/PEPexamplePart2.webp";

import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";
import { FAQ } from "../components/Utils";
import { TwoImagesGraphic } from "../components/Utils";

const headerPEPScreening = {
  h1: true,
  title: "PEP Screening",
  description: `Ensure compliance and mitigate risk with Checklynx’s comprehensive PEP screening solution. 
   ensuring your business stays ahead of potential risks and regulatory requirements.`,
};

const featuresPEPScreening = [
  {
    title: "Comprehensive PEP Identification",
    description: `Checklynx identifies PEPs and their close associates by searching global PEP lists. 
    Our solution enables you to perform comprehensive screenings during customer onboarding and ongoing monitoring, 
    ensuring no high-risk individuals are missed.`,
  },
  
  {
    title: "Accurate Screening with Advanced Algorithms",
    description: `Reduce false positives and improve screening accuracy with our advanced matching algorithms. By directly managing PEP data, 
    Checklynx offers high match accuracy and relevance, allowing you to focus on true risks.`,
  },
  {
    title: "Automated Compliance Reporting",
    description: `Ensure regulatory compliance with detailed audit trails and reporting features. 
    Our automated system keeps records of all PEP screenings and status changes, providing comprehensive reports for regulators and internal audits.`,
  },
  {
    title: "Efficient and Cost-Effective",
    description: `Our automated PEP screening solution is optimized for efficiency and affordability. With no need for third-party data resellers, you benefit from direct data access, ensuring both cost savings and timely compliance checks.`,
  },
];





const faqDataPEP = [
  { 
    question: "What is PEP screening and why is it important for compliance?", 
    answer: `PEP screening is the process of identifying Politically Exposed Persons (PEPs) in order to assess the potential risk they pose to a business.
     PEPs are individuals who hold prominent public positions or have significant influence, often increasing their exposure to corruption or money laundering activities. 
    PEP screening is crucial because businesses dealing with PEPs face a higher level of scrutiny from regulators. 
    By identifying PEPs early, businesses can apply enhanced due diligence, mitigating the risk of being involved in financial crimes or reputational damage.`,
  },
  { 
    question: "How can businesses protect themselves from legal risks with PEP screening?", 
    answer: `Businesses can protect themselves from legal and financial risks by implementing a 
    comprehensive PEP screening solution. Our Checklynx solution allows businesses to check both individuals and 
    their close associates against global PEP data, ensuring enhanced due diligence.`,
    
    answer2: `Automated PEP screening also reduces the need for manual checks and minimizes the chances of human error, helping businesses avoid penalties or sanctions.`,
  },
  { 
    question: "What are the advantages of using Checklynx for PEP screening?", 
    answer: `Checklynx manages its PEP data directly, ensuring accuracy and real-time updates without the delays or extra costs associated with third-party data resellers. 
    Our PEP screening solution leverages advanced search algorithms, reducing false positives and ensuring a higher match accuracy. With direct data control, Checklynx offers a reliable and secure way to stay compliant with PEP regulations, while also minimizing operational costs for businesses.`,
  },
 
  { 
    question: "Why is it important to screen both PEPs and their close associates?", 
    answer: `While PEPs themselves are high-risk due to their position or influence, their close associates—including family members, business partners, or colleagues—can also pose a significant risk to businesses. 
    Checklynx ensures that not only the PEP but also their associates are screened and monitored for potential red flags. This ensures thorough compliance, mitigating the risk of being indirectly involved in financial crimes through these associations.`,
  },
];



const carouselImages = [
  { src: PEPexample1, alt: 'Detailed example of PEP screening results highlighting target name and essential personal information for compliance' },
  { src: PEPexample2, alt: 'Comprehensive example of PEP screening results showcasing associates and contact information for enhanced due diligence' },
];




const CustomerScreeningPage = () => {
  return (
    <AppLayoutLogin>
      <Helmet>
        <link
          rel="canonical"
          href="https://checklynx.com/solutions/aml-pep-screening"
        />
        <title>
          How to Screen Customers, Individuals for Politically Exposed People. Compliance| Checklynx
        </title>
        <meta name="description" content="Learn how to screen individuals for Politically Exposed Persons (PEPs) with Checklynx.
         Ensure compliance with automated PEP screening and robust AML solutions to protect your business." />

      </Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section dir-col max-width16">
        <MainEpicContainer
          header={headerPEPScreening}
          features={featuresPEPScreening}
          carouselImages={carouselImages}
        />

       
       
      </div>
      <div className="main-section dir-col max-width12">
        <FAQ faqData={faqDataPEP}></FAQ>
        </div>

      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default CustomerScreeningPage;
