//landingPage
import React from "react";
import { Helmet } from "react-helmet";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";
import caseManagementActions from "../images/caseManagementActions.webp";
import caseEntityResults from "../images/caseEntityResults.webp";
import caseAndAuditLog from "../images/caseAndAuditLog.webp";

import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";
import { FAQ } from "../components/Utils";

const header = {
    h1: true,
    title: "Compliance Case Management for Teams",
    description: `Checklynx offers an efficient, centralized case management solution that simplifies investigations, collaboration, and reporting.`,
  };
  
  const features = [
    {
      title: "Avoid Overwhelming Workflows with Centralized Case Management",
      description: `Managing multiple AML cases across the team can lead to confusion and inefficiency. 
      Checklynx centralizes all case data, allowing teams to collaborate effectively and stay organized while handling ongoing investigations.`,
    },
    {
        title: "Assign and Prioritize Cases Based on Risk",
        description: `Checklynx allows you to prioritize cases by risk level, ensuring high-risk cases are addressed promptly. 
        You can easily assign cases to the appropriate team members and track their progress to ensure timely resolution.`,
      },
      {
        title: "Automate Case Closure and Reporting",
        description: `Once a case has been resolved, Checklynx automates case closure and keeps detailed log, 
        ensuring compliance with regulatory requirements and providing a clear audit trail.`,
      },
      {
        title: "Ensure Compliance with Complete Documentation",
        description: `Checklynx ensures that all case details, from investigation notes to risk assessments, 
        are securely stored and easily accessible, providing the documentation needed to meet regulatory demands and prepare for audits.`,
      },
    
  ];
  





const faqData = [
    {
        question: "How does Checklynx automate case management for compliance teams?",
        answer: `Checklynx automatically creates cases when a customer is flagged on one or more sanctions lists.
         Cases can be assigned to team members, and their progress, match status, and risk level can be tracked to ensure proper handling.`,
      },
  
    {
        question: "How does Checklynx handle case closure?",
        answer: `Once all relevant issues are resolved, 
        Checklynx automates case closure and stores all information securely. 
        This helps streamline the audit process and ensures complete documentation is maintained for each case.`,
      },

      {
        question: "How can a result or match be allowlisted for a customer?",
        answer: `Results or matches can be added to an allowlist (also known as safelist/whitelist) to prevent them from appearing in future searches for the same customer. 
        This process can be applied to individual results or performed as a batch operation for multiple entries, streamlining case management and reducing unnecessary alerts and false alarms.`,
      },
     
 
];


const carouselImages = [
  { src: caseAndAuditLog, alt: 'Allowlisting all results in compliance case management and audit log' },
  { src: caseManagementActions, alt: 'Case management actions in compliance software' },
  { src: caseEntityResults, alt: 'Entity results displayed for compliance case review' },
  
];




const CustomerScreeningPage = () => {
  return (
    <AppLayoutLogin>
     <Helmet>
  <link
    rel="canonical"
    href="https://checklynx.com/solutions/aml-case-management"
  />
  <title>Streamline AML Case Management for Compliance Teams | Checklynx</title>
  <meta
    name="description"
    content="Manage AML cases efficiently with Checklynx. Streamline investigations, 
    track case statuses, and ensure regulatory compliance with a centralized case management system tailored for AML needs."
  />
</Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section dir-col max-width16">
        <MainEpicContainer
          header={header}
          features={features}
          carouselImages={carouselImages}
        />

       
       
      </div>
      <div className="main-section dir-col max-width12">
        <FAQ faqData={faqData}></FAQ>
        </div>

      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default CustomerScreeningPage;
