import React from "react";

import { MainEpicContainer } from "../components/MainEpicContainer";
import con from "../config.json";


const headerWhyChecklynx = {
    titleSize: "large",
    title: "Why Choose Checklynx for AML Sanctions Screening",
    description: `Checklynx offers the most affordable and efficient solution for AML sanctions screening and customer ongoing monitoring. By managing data sources directly, we provide up-to-date and accurate information. Our streamlined, automated processes and flexible pay-per-use pricing make us the perfect choice for startups and businesses of all sizes.`,
  };
  
  const featuresData = [
    {
      title: "Unmatched Affordability with Flexible Pricing",
      description: `Our pay-per-use model ensures you only pay for the searches you perform, making compliance budget-friendly. Our streamlined and automated processes allow us to offer the most cost-effective solution without compromising on quality—ideal for startups and growing businesses.`,
    },
    {
        title: "Instant Setup with Self-Registration",
        description: `Unlike other companies that require expensive onboarding processes involving lengthy sales calls—costs which ultimately get passed to the customer—Checklynx offers a unique self-registration process. Get started immediately with no delays, onboarding fees, or hidden costs.`,
      },
    {
      title: "Direct Access to Accurate Data",
      description: `By managing data sources directly—not through resellers—we provide the most accurate and up-to-date information. This direct access eliminates middlemen, reducing costs and ensuring you have reliable data for compliance.`,
    },
    {
      title: "Comprehensive and Precise Screening",
      description: `Enhance your screening process by searching not just by name but also using unique identifiers like tax IDs, IBANs, passport numbers, and IMSOs for vessels. This ensures thorough compliance checks and reduces false positives.`,
    },
    
    {
      title: "Ready-to-Use, Structured Data Outputs",
      description: `Receive well-structured, actionable data outputs that integrate seamlessly into your existing systems. This saves you time and simplifies your compliance workflow, allowing you to focus on your core business.`,
    },
  ];
  




const WhyChecklynx = () => {
  return (
    <div className=" main-section dir-col  bg-backroundColor">
       <div className=" max-width14 ">
        <MainEpicContainer 
        header={headerWhyChecklynx} 
        button={{ label: "Start your journey", url: con.urls.checklynx_app_sign_up }} 
        features={featuresData}
        />
      </div>
     
    </div>
  );
};

export default WhyChecklynx;
