import React from "react";
import con from "../config.json";


import stripe_logo from "../images/stripe.svg";
import aws_logo from "../images/aws.svg";
import { LogoCard } from "./Utils";

const FindUsIn = () => {
  return (
    
      <div className="main-section dir-col">
       
         

        
          <p className="text6">Find us in</p>
          <div className="buttons-row ">
            <LogoCard
              bgClass="card-logo"
              externalLink={con.urls.stripe_marketplace_product}
              iconSVG={stripe_logo}
              alt="Checklynx compliance software available in the Stripe Marketplace for seamless integration"
              
            ></LogoCard>

            <LogoCard
              bgClass="card-logo "
              externalLink={con.urls.aws_marketplace_product}
              iconSVG={aws_logo}
              alt="Checklynx compliance software available in the AWS Marketplace for easy integration with cloud services"
            ></LogoCard>
          </div>
        </div>
       
     
    
  );
};

export default FindUsIn;
