//landingPage
import React from "react";
import { Helmet } from "react-helmet";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";

import caseAndAuditLog from "../images/caseAndAuditLog.webp";

import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";
import { FAQ } from "../components/Utils";

const header = {
  h1: true,
  title: "Audit and Reporting for Compliance Management",
  description: `Automatically track and log every customer and case activity for complete transparency. Checklynx ensures all edits, results, 
  status changes, and team actions are captured and displayed for auditing and compliance purposes.`,
};

const features = [
  {
    title: "Automated Logging of Customer and Case Activities",
    description: `Checklynx automatically logs every change made to customer data and cases, including status updates, team member assignments, 
    and any notes or updates added by your team. This comprehensive audit trail ensures you have a complete record of all actions taken.`,
  },
  {
    title: "Track Status Changes and Case Progress",
    description: `Every status change, from case creation to closure, is logged and time-stamped, giving you a detailed view of each case’s 
    lifecycle. With clear progress tracking, you can monitor risk levels, assignments, and actions taken by team members.`,
  },
  {
    title: "Real-Time Audit Data Displayed for Compliance",
    description: `All audit logs are available in the portal, allowing you to quickly view customer edits, case updates, 
    results, and team member actions. This ensures your compliance team has full visibility into ongoing activities without needing to generate separate reports.`,
  },
];

  





const faqData = [

  {
    question: "What information is logged in Checklynx's audit trail?",
    answer: `Checklynx logs all critical activities, including customer data edits, results found during sanctions screening, case status changes, team member assignments, and notes. This comprehensive audit trail provides complete visibility into compliance-related actions.`,
  },
  {
    question: "How does Checklynx help with compliance reporting?",
    answer: `Checklynx provides real-time access to audit logs within the platform. You can easily track case updates, customer edits, and team activities without the need to generate separate reports, ensuring compliance with regulatory requirements.`,
  },
  {
    question: "Can I track case progress and team member actions in Checklynx?",
    answer: `Yes, Checklynx allows you to track every action taken on a case, from case creation to closure. Team member assignments, status changes, risk levels, and match statuses are all logged and displayed in real-time for full transparency.`,
  },
  {
    question: "How does Checklynx ensure compliance with AML regulations?",
    answer: `Checklynx automatically tracks all changes and activities related to customers and cases, ensuring that you have a complete audit trail to demonstrate compliance with AML regulations. Real-time logging and transparent case management help ensure nothing is missed.`,
  },
 
];


const carouselImages = [
  { src: caseAndAuditLog, alt: 'audit log for comliance management' },
 
  
];




const AuditReportingPage = () => {
  return (
    <AppLayoutLogin>
     <Helmet>
  <link
    rel="canonical"
    href="https://checklynx.com/solutions/aml-case-management"
  />
  <title>Streamline AML Case Management for Compliance Teams | Checklynx</title>
  <meta
    name="description"
    content="Manage AML cases efficiently with Checklynx. Streamline investigations, 
    track case statuses, and ensure regulatory compliance with a centralized case management system tailored for AML needs."
  />
</Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section dir-col max-width16">
        <MainEpicContainer
          header={header}
          features={features}
          carouselImages={carouselImages}
        />

       
       
      </div>
      <div className="main-section dir-col max-width12">
        <FAQ faqData={faqData}></FAQ>
        </div>

      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default AuditReportingPage;
