//landingPage
import React from "react";
import contact_us from '../images/contact_us.webp'
import AppLayoutLogin from "../components/AppLayoutLogin";
import { Helmet } from "react-helmet";

import Footer from "../components/Footer";
import customerView from "../images/customerView.webp";

import con from "../config.json";

import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";


const header = {
  h1: true,
  title: "Contact Us for Compliance Software Solutions",
  description: `How can we assist you with sanctions and PEP compliance? We're dedicated to supporting you at every stage of your compliance journey.`,
};

const features = [
  {
      title: "Schedule a Compliance Demo",
      description: `Experience our cutting-edge sanctions and PEP screening tools in action with a personalized demo.`,
  },
  {
      title: "Request Compliance Support",
      description: `Get personalized assistance from our expert team to resolve your queries and ensure smooth sanctions and PEP compliance.`,
  },
  {
      title: "Partner with Checklynx",
      description: `Explore partnership opportunities with Checklynx to enhance your compliance capabilities.`,
  },
  {
    title: "Write us",
    description: `contact@checklynx.com`,
}
];
const button = {
  label: "Schedule a call",
  url: con.calendly.url,
  newTab:true,
};

const CustomerOngoingMonitoringPage = () => {


  return (
    <AppLayoutLogin>
        <Helmet>
        <link rel="canonical" href="https://checklynx.com/aml-customer-screening/" />
      </Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section max-width16">
        <MainEpicContainer
          header={header}
          features={features}
          graphic={contact_us}
          grapchicAlt="Dashboard displaying daily sanctions checks for customers in compliance monitoring"
          button={button}
        />
      </div>

     

      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default CustomerOngoingMonitoringPage;


