//Cards.jsx
import React from 'react';


import { useInView } from 'react-intersection-observer';

export const ProductCard = ({ link, bgClass, iconSVG, heading, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.01,  // Adjust the threshold as needed for when you want the animation to start

  });
  return (
   
      <div onClick={() => {
        const section = document.getElementById(link);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }}>
    <div
    ref={ref} 
    className={`card bg-dark-selection align-center  ${inView ? 'animate' : ''}`}
    >
       
      <h3 className="text-dark no-margin">{heading}</h3>
      
      
      </div>
      </div>
   
  );
};





 export const ColoredCards = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.2, // Adjust as needed
  });
    return (
     
      <div className=" gradientLila"> 
    <div className="main-section" >
      <div className="dir-col  max-width7 " > 

      <h2 ref={ref} className={`main-header-title  white ${inView ? 'animate' : ''}`}>
      Streamline Your Compliance Workflow with Our Sanctions & PEP Platform
      </h2>
      <div style={{ height: "1rem" }}></div>
        <div className="product-cards">
        
        <ProductCard 
            link="databases"
            heading="Up-to-Date Global Sanctions & PEP Data for Accurate Screening"
            text="Global and national sources continuously updated"
          />
          <ProductCard 
            link="data-xperience"
            heading="Perfectly Structured Global Sanctions & PEP Data"
            
          />
          <ProductCard 
            link="search-experience"
            heading="Enhanced Search Experience to minimize false alarms"
            text="Crafted to deliver relevant results and minimize false alarms"
          />
          
          
         <ProductCard 
            link="integration"
            heading="Seamless API Integration for fast onboarding"
            text="Built with developers in mind, designed for effortless integration"
          />
        </div>
        </div>
       
      
      </div>
      </div>
    );
  };
  
  