import { useInView } from "react-intersection-observer";
import React from 'react';
import { Collapse } from 'antd';
export const AnimatedCard = ({ bgClass, children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust the threshold as needed for when you want the animation to start
  });
  return (
    <div
      ref={ref}
      className={`${bgClass}   flex bs-dir-row justify-center ${
        inView ? "animate" : ""
      }`}
    >
      <div className="flex  justify-center">{children}</div>
    </div>
  );
};



export const LogoCard = ({ externalLink, bgClass, iconSVG, heading, text,alt }) => {
  

  const handleClick = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');  // Opens the link in a new tab
      // Alternatively, use window.location.href = externalLink; to open in the same tab
    }
  };

  return (
    <div onClick={handleClick}>
      <div
        
        className={`${bgClass} align-center `}
      >
       {iconSVG && <img src={iconSVG} alt={alt} className='img-responsive logo-size' />}
        {heading && <h3 className="text-dark no-margin">{heading}</h3>}
        {text && <h6 className="text-dark no-margin">{text}</h6>}
      </div>
    </div>
  );
};

export const TwoImagesGraphic = ({
  imgSrc1,
  imgSrc2,
  alt1 = "Image 1",
  alt2 = "Image 2",
}) => {
  if (!imgSrc1 || !imgSrc2) return null;

  return (
    <div className="  graphic-form container-graphic ">
      <div className=" two-images-graphic  max-width4">
        <img
          src={imgSrc1}
          alt={alt1}
          className="graphic-form img   first-image img-bezel"
        />
        <img
          src={imgSrc2}
          alt={alt2}
          className=" graphic-form img   second-image img-bezel"
        />
      </div>
    </div>
  );
};




const { Panel } = Collapse;


export const FAQ = ({ faqData }) => {
  return (
    <Collapse accordion>
    {faqData.map((faq, index) => (
      <Panel header={<h4 className="feature-item-title">{faq.question}</h4>} key={index}>
        <p>{faq.answer}</p>
        {faq.answer2 && <p>{faq.answer2}</p>}
        {faq.answer3 && <p>{faq.answer3}</p>}
      </Panel>
    ))}
  </Collapse>
  );
};

