import React, { useEffect, useState } from "react";


import { ReactSVG } from "react-svg"; // Import ReactSVG component
import whiteMobileSearch from "../images/whiteMobileSearch.webp";
// Import icons
import api from "../icons/api.svg";
import cloud from "../icons/cloud.svg";
import world from "../icons/world.svg";
import mobile from "../icons/mobile.svg";
import dashboard from "../icons/dashboard.svg";

import "./DynamicImage.css";

const ImageComponent = () => (
  <div className="center-image img container-phone-graphic">
    <img
      src={whiteMobileSearch}
      loading="lazy"
      alt="Search interface for vessels, aircraft, companies, and individuals in sanctions and compliance database"
    />
  </div>
);

const IconsAndLinesComponent = () => {
  const [activeIcons, setActiveIcons] = useState([]);

  useEffect(() => {
    const iconClasses = ['icon1', 'icon2', 'icon3', 'icon4', 'icon5'];

    const activateRandomIcon = () => {
      const randomIcon =
        iconClasses[Math.floor(Math.random() * iconClasses.length)];

      // Add the random icon to the array of active icons
      setActiveIcons((prevIcons) => [...new Set([...prevIcons, randomIcon])]);

      // Random active time between 1 and 2.5 seconds
      const randomActiveTime = Math.random() * (2500 - 1000) + 1000;

      // Reset the icon after the random duration
      setTimeout(() => {
        setActiveIcons((prevIcons) =>
          prevIcons.filter((icon) => icon !== randomIcon)
        );
      }, randomActiveTime);
    };

    const interval = setInterval(activateRandomIcon, 500); // Activates a new icon every 0.5 seconds

    return () => {
      clearInterval(interval); // Cleanup on component unmount
    };
  }, []);

  const icons = [
    { id: 'icon1', src: api },
    { id: 'icon2', src: cloud },
    { id: 'icon3', src: world },
    { id: 'icon4', src: mobile },
    { id: 'icon5', src: dashboard },
  ];

  return (
    <>
      <svg
        loading="lazy"
        className="line-svg"
        width="100%"
        height="100%"
        viewBox="0 0 530 660"
      >
        {/* SVG Paths */}
        <path
          d="M 70,433 L 70,390 A 10,10 0 0 1 80,380 L 240,380"
          strokeWidth="2"
          fill="none"
          className={
            activeIcons.includes('icon1')
              ? 'active-line line1'
              : 'inactive-line line1'
          }
        />
        <path
          d="M 70,227 L 70,290  L 70,290 A10,10 1 0 0 77,296  L 240,296 "
          strokeWidth="2"
          fill="none"
          className={
            activeIcons.includes('icon2')
              ? 'active-line line2'
              : 'inactive-line line2'
          }
        />
        <path
          d="M 380,599 L 250,599 A 10,10 0 0 1 240,589 L 240,340"
          strokeWidth="2"
          fill="none"
          className={
            activeIcons.includes('icon3')
              ? 'active-line line3'
              : 'inactive-line line3'
          }
        />
        <path
          d="M 460,425 L 460,490 A 20,20 0 0 1 440,510 L 270,510"
          strokeWidth="2"
          fill="none"
          className={
            activeIcons.includes('icon4')
              ? 'active-line line4'
              : 'inactive-line line4'
          }
        />
        <path
          d="M 460,140 L 460,250 A 20,20 0 0 1 450,270 L 240,380"
          strokeWidth="2"
          fill="none"
          className={
            activeIcons.includes('icon5')
              ? 'active-line line5'
              : 'inactive-line line5'
          }
        />
      </svg>

      {/* Render Icons */}
      {icons.map((icon) => (
        <ReactSVG
          key={icon.id}
          src={icon.src}
          loading="lazy"
          className={`icon ${icon.id} ${
            activeIcons.includes(icon.id) ? 'active' : ''
          }`}
        />
      ))}
    </>
  );
};


export const DynamicImage = ({
  showIconsAndLines = true,
  isAbsolute = false,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1320);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isSmallScreen) {
    return <div style={{ marginTop: "-410px" }}></div>;
  }

  return (
    <div className="container flex align center sticky-position ">
      <div>
        <ImageComponent />
        {showIconsAndLines && <IconsAndLinesComponent />}
      </div>
    </div>
  );
};
