
import React, { useState, useEffect } from "react";

import con from '../config.json';

import {
  MenuOutlined} from "@ant-design/icons";


import { Button, Popover, Drawer, Collapse } from 'antd';
import {  useNavigate } from 'react-router-dom';
import "./Header.css";


const generateMenuContent = (mainItem, subItems, inSideMenu) => (
  <li className="menu-item">
    {!inSideMenu && (  // Conditionally hide the title and description
      mainItem.url ? (
        <a
          className="mega-menu-title link"
          href={mainItem.url}
          target={mainItem.url.startsWith('http') ? '_blank' : '_self'}
          rel={mainItem.url.startsWith('http') ? 'noopener noreferrer' : ''}
        >
          {mainItem.title}
          <div className="mega-menu-description">
            {mainItem.description}
          </div>
        </a>
      ) : (
        <span className="mega-menu-title">
          {mainItem.title}
          <div className="mega-menu-description">
            {mainItem.description}
          </div>
        </span>
      )
    )}

    {subItems.length > 0 && (  // Only render sub-menu if subItems exist
      <ul className={inSideMenu ? 'sub-menu side-menu' : 'sub-menu grid-menu'}>
        {subItems.map((subItem) => (
          <li key={subItem.url} className="sub-menu-item">
            <a
              href={subItem.url}
              className="sub-menu-link"
              target={subItem.url.startsWith('http') ? '_blank' : '_self'}
              rel={subItem.url.startsWith('http') ? 'noopener noreferrer' : ''}
            >
              {subItem.title}
              {!inSideMenu && (<div className="sub-menu-description">{subItem.description}</div>)}
            </a>
          </li>
        ))}
      </ul>
    )}
  </li>
);





export const HeaderComp = () => {
  const navigate = useNavigate();
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [showStickyHeader, setShowStickyHeader] = useState(false);


  const handleLogoClick = () => {
    navigate("/");
  };

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  // Handle scroll event to show sticky header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {  // Adjust the pixel value as needed
        setShowStickyHeader(true);
      } else {
        setShowStickyHeader(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    { label: "Solutions", content: generateMenuContent(SolutionsMenu.mainItem, SolutionsMenu.subItems,isDrawerVisible) },
    { label: "Developers", content: generateMenuContent(DevelopersMenu.mainItem, DevelopersMenu.subItems,isDrawerVisible) },
    { label: "Pricing", content: generateMenuContent(pricingMenu.mainItem, pricingMenu.subItems,isDrawerVisible) }
  ];

  return (
    <>
      <header className={`header-container ${showStickyHeader ? 'sticky-header' : ''}`}>
        <div className="header-content side-padding3-1">
          <div onClick={handleLogoClick} className="site-header-logo">
            Checklynx
          </div>
          <div className="header-nav">
            {navItems.map((item) => (
              <Popover
                key={item.label}
                content={item.content}
                placement="bottom"
                trigger="hover"
              >
                <Button type="text">{item.label}</Button>
              </Popover>
            ))}
          </div>
          <div className="header-actions-large">
            <Button className="action-button-secondary" onClick={() => handleNavigation(con.urls.checklynx_app + "/login")}>
              Log in
            </Button>
            <Button className="action-button-primary" onClick={() => handleNavigation(con.urls.checklynx_app_sign_up)}>
              Create Account
            </Button>
          </div>
          <div className="header-actions-small">
            <MenuOutlined onClick={showDrawer} />
          </div>
        </div>
      </header>

      <Drawer title="Checklynx" placement="right" onClose={onClose} open={isDrawerVisible}>
        <div className="menu-drawer">
          <Button type="primary" aria-label="Start for Free and Try 30 Days" style={{ width: '80%', marginTop: "1rem" }} onClick={() =>handleNavigation(con.urls.checklynx_app_sign_up)}>
           Try 30 days Free
          </Button>
          <Button type="primary" ghost style={{ width: '80%', marginTop: "1rem", marginBottom: "1rem" }} onClick={() => handleNavigation(con.urls.checklynx_app + "/login")}>
            Log in
          </Button>
        
         
          <Collapse defaultActiveKey={['0', '1', '2']} className="padding-zero drawer-container-collapse">
            {navItems.map((item, index) => (
              <Collapse.Panel header={item.label} key={index}>
                {item.content}
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </Drawer>
    </>
  );
};




const SolutionsMenu = {
  mainItem: {
    title: 'Checklynx software AML Solutions',
    url: '/',
    description: 'AML Compliance for Sanctions Screening and PEP checks.'
  },
  subItems: [
    {
      title: 'Sanctions Screening',
      url: '/solutions/aml-sanctions-screening/',
      description: 'Streamline onboarding to quickly detect and evaluate risk from clients.'
    },

    {
      title: 'Ongoing Monitoring',
      url: '/solutions/aml-customer-ongoing-monitoring/',
      description: 'Automated daily monitoring and case management'
    },
    {
      title: 'PEP Screening',
      url: '/solutions/aml-pep-screening',
      description: 'Detect Politcally Exposed Individuals'
    },
    {
      title: 'Crypto Asset Compliance',
      url: '/solutions/aml-crypto-assets-screening',
      description: 'Ensure AML compliance in cryptocurrency transactions by screening crypto wallets.'
    },

    {
      title: 'Maritime and Aviation Compliance',
      url: '/solutions/aml-maritime-aviation-screening',
      description: 'Comply with international regulations by screening vessels and aircraft.'
    },
    {
      title: 'Compliance Case Management',
      url: '/solutions/aml-case-management',
      description: 'Efficiently manage compliance cases when potential risks are detected.'
    },
    
    {
      title: 'Audit and Compliance Reporting',
      url: '/solutions/aml-audit-reporting',
      description: 'Maintain a comprehensive audit trail for regulatory compliance and internal notes.'
    },



  ]
};





const pricingMenu = {
  mainItem: {
    title: 'Pricing',
     url: '/pricing/',
    description: 'Flexible pricing for businesses of any size'
  },
  subItems: [
    {
      title: 'Start from $99',
      url: '/pricing/',
      
    },
    
  ]
};


const DevelopersMenu = {
  mainItem: {
    title: 'Developers portal',
     url: con.urls.dev_portal,
    description: "Kickstart your integration. Find all the necessary documentation in one centralized portal."
  },
  subItems: [
    {
      title: 'API documentation',
      url: con.urls.dev_portal+"/docs/intro",
      description: "Comprehensive concepts and examples in Python and JavaScript."
    },
    {
      title: 'Swagger',
      url: "https://app.swaggerhub.com/apis/checklynx-ai/checklynx/",
      description: 'Swagger API documentation for exploration'
    },
    {
      title: 'Postman',
      url: "https://documenter.getpostman.com/view/37023208/2sA3kRHiM5",
      description: 'Pre-configured Postman examples for quick API testing'
    },
    
  ]
};