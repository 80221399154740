//landingPage
import React from "react";
import { Helmet } from "react-helmet";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";
import search_mobile_dark from "../images/search_mobile_dark.webp";
import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";
import { FAQ } from "../components/Utils";

const headerAdvanceSanctionScreening = {
  h1: true,
  title: "Advance Sanction Screening",
  description: `Discover risks across millions of entities and individuals in over 235 countries and states. 
    Checklynx provides fast insights through a trusted and up-to-date database, consolidating 200+
    domestic and international sanction regimes. With coverage of companies, 
    individuals, vessels, and aircraft, it delivers industry-leading sanction compliance solutions for your business.`,
};

const featuresAdvanceSanctionScreening = [
  {
    title: "Safe onboarding",
    description: `Establish an AML control program from the moment you open a customer account by assessing their risk levels. 
    Screen them against global and domestic sanctions lists.`,
  },
  {
    title: "Prevent Legal Risks and Protect Your License",
    description: `Identify sanctions against individuals, entities, vessels, and aircraft. 
    Perform searches by name or any identifier such as tax ID, passport number, or driver’s license to ensure complete compliance.`,
  },
  {
    title: "Uncover Hidden Connections",
    description: `Ensure full compliance with AML/CFT regulations by thoroughly exploring these hidden networks ownerships and assotiations.`,
  },
  {
    title: "Maintain Comprehensive Audit Trails",
    description: `By integrating automated tracking of audit trails with preset matching parameters like fuzzy matching and risk scores, we ensure seamless compliance reporting. Our solution provides MLROs with a complete, 
    real-time record of all transactions, reducing manual processes while meeting stringent regulatory standards.`,
  },
];


const faqData = [
  { question: "What is sanctions screening and why is it important for compliance?", 
    answer: `Sanctions screening is the process of checking individuals, entities, and organizations against global sanctions lists to ensure they are not involved
     in any prohibited activities. This practice is crucial for compliance as sanctions lists are regularly updated by governments and international bodies to combat financial 
     crimes, terrorism, and other illegal activities. Organizations face significant challenges with sanctions compliance because there is no single, definitive sanctions list. They must regularly monitor multiple lists and ensure they do not engage in any transactions with sanctioned entities. Failure to comply can result in legal penalties, fines, and reputational damage. 
     Using advanced software, like our AML customer screening and ongoing monitoring solutions, ensures your business stays compliant by automatically checking and updating sanctions lists in real-time.` },
  { 
    question: "How can businesses protect themselves from legal risks with advanced sanctions screening?", 
    answer: `Businesses can protect themselves from legal risks by implementing advanced sanctions screening that thoroughly checks individuals, entities, vessels, and aircraft against global sanctions lists. Our solution, Checklynx, allows businesses to perform searches using names or unique identifiers such as tax ID, passport numbers, or driver’s licenses. This ensures no high-risk individuals or entities are missed during customer onboarding or ongoing monitoring.`,
    answer2: `By identifying sanctions and hidden associations early, businesses can prevent illegal transactions and protect their licenses from regulatory breaches.`,
    answer3: `Comprehensive audit trails, combined with automated tracking and fuzzy matching of risk scores, provide real-time insights for MLROs (Money Laundering Reporting Officers). This minimizes manual processes, reduces errors, and helps businesses meet AML and CFT compliance standards while avoiding costly legal consequences.`
  },

  { question: `What are the advantages of using a non-data reseller SaaS for compliance?`, 
    answer: `Our SaaS directly manages data by fetching, parsing, and applying advanced search neural algorithms, giving you accurate, real-time insights tailored to your needs. This ensures fewer false positives and more relevant results. With full control over the data, we provide enhanced security 
    and compliance without the extra costs or delays of third-party data resellers, offering a more efficient and reliable solution for your business` },

    { question: `How does Checklynx help with sanctions screening for vessels and aircraft?`, 
      answer: `Checklynx enables you to screen vessels using identifiers like IMO (International Maritime Organization number), MMSI (Maritime Mobile Service Identity),
       and Call sign, and aircraft by their aircraft number. 
       By checking these identifiers against global sanctions lists, our system helps ensure compliance with international 
       regulations and mitigates risks tied to sanctioned vessels and aircraft. This comprehensive screening protects
        your business from engaging with high-risk or blacklisted entities.` }
];


const CustomerScreeningPage = () => {
  return (
    <AppLayoutLogin>
      <Helmet>
        <link
          rel="canonical"
          href="https://checklynx.com/solutions/aml-sanctions-screening/"
        />
        <title>  How to Screen Customers, Individuals, Companies, and Vessels for AML Compliance | Checklynx </title>
        <meta name="description" content="Checklynx offers fast insights with an up-to-date database, consolidating over 200 domestic 
        and international sanction lists such UN, OFAC, BIS, HMT, SECO and EU. Comprehensive coverage of companies, individuals, vessels, and aircraft." />

      </Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section dir-col max-width16">
        <MainEpicContainer
          header={headerAdvanceSanctionScreening}
          features={featuresAdvanceSanctionScreening}
          graphic={search_mobile_dark}
          grapchicAlt="Responsive layout for mobile and desktop to screen customers against global and domestic sanctions list"
        />

        
       
      </div>
      <div className="main-section dir-col max-width12">
        <FAQ faqData={faqData}></FAQ>
        </div>

      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default CustomerScreeningPage;
