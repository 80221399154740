//landingPage
import React from "react";
import { Helmet } from "react-helmet";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";
import cryptowalletDetail from "../images/cryptowalletDetail.webp";
import searchCryptowallet from "../images/searchCryptowallet.webp";
import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";
import { FAQ } from "../components/Utils";
import { TwoImagesGraphic } from "../components/Utils";



const headerCryptos = {
  title: "AML Check for Crypto Addresses",
  description: `Ensure transparency and compliance in your cryptocurrency transactions with Checklynx. Our cutting-edge solution screens crypto wallet addresses against global sanction lists, enhancing the security and trustworthiness of your dealings with customers using crypto wallets.`,
};

const featuresCryptos = [
  {
    title: "Automatic Sanction List Screening",
    description: `Effortlessly use our API or portal to screen for crypto wallet addresses against all major global sanction lists to ensure compliance and mitigate risks associated with illicit activities.`,
  },
  {
    title: "Real-Time Risk Assessment",
    description: `Use the API to instantly evaluate the risk levels of crypto wallets, enabling proactive decision-making and enhancing the safety of your transactions.`,
  },
];


const CryptoImg = (
  <TwoImagesGraphic
    imgSrc1={searchCryptowallet}
    imgSrc2={cryptowalletDetail}
    alt1="how to screen a cryptowallet address agains sanctions list"
    alt2="result of the cryptowallet screen ready for compliance check"
  />
);

const faqData = [
  
  { question: `How does Checklynx ensure AML compliance for cryptocurrency wallet addresses?`, 
    answer: `With Checlynx you can screen for crypto wallet addresses against major global sanction lists, 
    including sources like the U.S. OFAC, EU sanctions, and the UN Security Council. This is crucial for preventing transactions with blacklisted individuals or entities involved in illegal activities such as money laundering or terrorism financing. 
    Our real-time risk assessment API helps you quickly evaluate the risk levels of wallets, keeping your transactions safe and compliant with AML regulations.` },

    { question: `Why is it not allowed to do business with a crypto wallet under sanctions?`, 
        answer: ` Conducting business with a crypto wallet that is under sanctions is illegal 
        and poses significant risks. Sanctions are imposed by governments such as the U.S. Office of Foreign Assets Control (OFAC) and international bodies to prevent financial
        interactions with individuals, entities, or countries involved in activities like terrorism, money laundering,
         or human rights violations. Engaging with a sanctioned wallet can lead to severe legal consequences, including 
         fines and criminal charges, and may inadvertently support illicit activities.
         To protect your business and comply with legal obligations, it's essential to avoid transactions with any sanctioned crypto wallets.`},
];


const CryptoAssetPage = () => {
  return (
    <AppLayoutLogin>
      <Helmet>
  <link
    rel="canonical"
    href="https://checklynx.com/solutions/aml-crypto-assets-screening/"
  />
  <title>How to Screen Cryptowallets for AML Compliance | Checklynx</title>
  <meta
    name="description"
    content="Discover effective solutions for Anti-Money Laundering (AML) compliance in cryptocurrency transactions. 
    Learn how Checklynx helps you screen cryptowallets to prevent fraud and ensure regulatory adherence."
  />
</Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section dir-col max-width16">
       

        <div className="">
          <MainEpicContainer
            header={headerCryptos}
            features={featuresCryptos}
            imgComp={CryptoImg}
          />
        </div>
       
      </div>
      <div className="main-section dir-col max-width12">
        <FAQ faqData={faqData}></FAQ>
        </div>

      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default CryptoAssetPage;
