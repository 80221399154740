import React from "react";
import associates from "../images/associates.webp";
import identification from "../images/identification.webp";
import addresses from "../images/addresses.webp";
import { useInView } from "react-intersection-observer";
import {AnimatedCard} from "./Utils";
import { Carousel } from "antd";

const DataExperience = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Adjust as needed
  });
  return (
    <div
      id="data-xperience" className="dir-col   main-section centered  bg-backroundColor" >
      <div className="flex header max-width12 align-center">
        <h2
          ref={ref}
          className={` display-heading dir-row flex ${inView ? "animate" : ""}`}
        >
          Optimized Data Experience for Accurate Sanctions Screening
        </h2>

        <h4 ref={ref} className={`  align-center ${inView ? "animate" : ""}`}>
          Checklynx transforms complex, raw data into a structured,
          easy-to-navigate format.
        </h4>

        <h4 ref={ref} className={`   ${inView ? "animate" : ""}`}>
        Reduce the number of records needing to be reviewed. 
        Our targets are deduplicated, all information by the sanction issuers are available on that target. 
          
        </h4>
      </div>

      <div className="flex-no-wrap xpirience-cards2 b-padding1 ">
        <FeaturesCards1></FeaturesCards1>

        <SimpleCarousel></SimpleCarousel>
      </div>

      <div className="grid-container-dex  b-padding2 side-padding3-1">
       
       {/* <FeaturesCards3> </FeaturesCards3>*/}
      </div>
    </div>
  );
};

export default DataExperience;




export const SimpleCarousel = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust the threshold as needed for when you want the animation to start
  });
  return (
    <div
      ref={ref}
      className={`  card-feature focus-bg  t-padding2   ${
        inView ? "animate" : ""
      }`}
    >
      <div className="carousel-container  side-padding1 ">
        <div className="carousel-dark-mode ">
          <Carousel
            arrows
            dotPosition="bottom"
            infinite={false}
            autoplay={false}
            autoplaySpeed={6000}
          >
            <div className="flex card-feature side-padding2 b-padding2">
              <div className="flex white justify-center side-padding2">
                <h6>ISO-compliant formats for countries and dates</h6>
                <img
                  src={addresses}
                  alt="Data converted into ISO-compliant formats for countries, dates, and IDs for seamless compliance"
                  className="img-responsive max-width4"
                  loading="lazy" // Image will load lazily
                />
              </div>
            </div>
            <div className="flex card-feature side-padding2  ">
              <div className="flex white justify-center side-padding2 b-padding2">
                <h6>Passports, reg. numbers, SWIFT, national IDs...</h6>

                <img
                  src={identification}
                  alt="Data extraction for IDs, passports, Tax IDs, IBAN accounts, and cryptowallets into standardized formats for compliance"
                  className="img-responsive max-width4"
                  loading="lazy" // Image will load lazily
                />
              </div>
            </div>

            <div className="flex card-feature side-padding2 b-padding2 ">
              <div className="flex white justify-center side-padding2">
                <h6>Categorizes human and business links</h6>
                <img
                  src={associates}
                  alt="Categorization of human and business links between sanctioned individuals and companies for compliance tracking"
                  className="img-responsive max-width4"
                  loading="lazy" // Image will load lazily
                />
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};



export const FeaturesCards1 = () => {
  return (
    <div className="xpirience-cards1 justify-space-between  dir-col ">
      <AnimatedCard bgClass="card-feature focus-bg focus-xpirience">
        <h3 className="no-margin">
          Dispare data into consistent structured formats
        </h3>
      </AnimatedCard>
      <AnimatedCard bgClass="card-feature focus-bg focus-xpirience">
        <h3 className="no-margin">
          Simplified: Less time decoding, more time acting
        </h3>
      </AnimatedCard>
    </div>
  );
};

export const FeaturesCards3 = () => {
  return (
    <div className="grid-container-dex-f  ">
      <AnimatedCard bgClass="card-feature grid-item-dex-f focus-bg focus-xpirience">
        <h3 className="no-margin">Faster learning curve</h3>

        
      </AnimatedCard>
      <AnimatedCard bgClass="card-feature grid-item-dex-f focus-bg focus-xpirience">
        <h3 >Contextual help</h3>

        <h5 className="no-margin">
            User guidance through all tasks.
        </h5>
      </AnimatedCard>

      <AnimatedCard bgClass="card-feature grid-item-dex-f focus-bg focus-xpirience">
        <h3>Responsive design</h3>

        <h5 className="no-margin">
          Optimized for all screen sizes and devices, in bright and dark modes.
        </h5>
      </AnimatedCard>
    </div>
  );
};
