import React, { useState } from 'react';

import { Button ,Carousel} from 'antd';
import { RightOutlined, UpOutlined } from '@ant-design/icons';
import { useNavigate, } from 'react-router-dom';

import vesselInfo from "../images/vesselInfo.webp";
import vesselTarget from "../images/vesselTarget.webp";

const MainHeader = ({ title, description, titleSize = 'default', h1 = false }) => {
  if (!title || !description) return null;

  // Choose the heading tag based on the h1 prop
  const Tag = h1 ? 'h1' : 'h2';

  // Determine the CSS class for font size
  const fontSizeClass = titleSize === 'large' ? 'large-heading' : 'default-heading';

  return (
    <div className="main-header">
      <Tag className={`main-header-title ${fontSizeClass}`}>{title}</Tag>
      <h2 className='main-header-subtitle'>{description}</h2>
    </div>
  );
};

// CTAButton Component


const CTAButton = ({ label, url, newTab }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  if (!label || !url) return null;

  const handleClick = () => {
    if (url.startsWith('http')) {
      // External URL
      if (newTab) {
        window.open(url, '_blank'); // Open in new tab
      } else {
        window.location.href = url; // Same tab
      }
    } else {
      // Internal URL
      navigate(url);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Button
        className="cta-button"
        onClick={handleClick}
        size="large"
        type="primary"
        onMouseEnter={() => setIsHovered(true)}  // Set hover to true
        onMouseLeave={() => setIsHovered(false)} // Set hover to false
      >
        {label} {isHovered ? <UpOutlined /> : <RightOutlined />} {/* Icon toggle */}
      </Button>
      <div style={{ height: '2rem' }} />
    </>
  );
};

export default CTAButton;



// FeatureItem Component
const FeatureItem = ({ icon, title, description }) => {
  if (!title || !description) return null;
  return (
    <div className="feature-item">
      {icon && <div>{icon}</div>}
      <h3 className='feature-item-title'>{title}</h3>
      <h4 className='feature-item-description'>{description}</h4>
    </div>
  );
};

// FeaturesList Component
const FeaturesList = ({ features }) => {
  if (!features || features.length === 0) return null;
  return (
    <div className="features-list">
      {features.map((feature, index) => (
        <FeatureItem
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  );
};

// GraphicForm (Image Holder) Component
const GraphicForm = ({ imgSrc, alt}) => {
  if (!imgSrc) return null;
  return (
    <div className="graphic-form container-graphic">
      <img src={imgSrc} alt={alt} />
    </div>
  );
};

// MainEpicContainer Component
export const MainEpicContainer = ({ header, button, features, graphic,grapchicAlt,imgComp,carouselImages }) => {



  return (
    <div className="main-epic-container">
      <MainHeader title={header?.title} description={header?.description} titleSize={header?.titleSize} h1={header?.h1}/>
      <CTAButton label={button?.label} url={button?.url} newTab={button?.newTab} /> 
      <div style={{ height: '2rem' }} />
      <div className='main-epic-graphic-grid'>
      <FeaturesList features={features} />
      <GraphicForm imgSrc={graphic} alt={grapchicAlt} />
      {imgComp && <div className="">{imgComp}</div>}
      <SimpleCarousel carouselImages={carouselImages}></SimpleCarousel>
      
      </div>
    </div>
  );
};




export const SimpleCarousel = ({ carouselImages }) => {
  if (!carouselImages || carouselImages.length === 0) return null;

  return (
    <div className="carousel-container container-graphic side-padding1">
      <div style={{ width: '100%' }}>
        <Carousel
          arrows
          dotPosition="bottom"
          infinite={false}
          autoplay={true}
          autoplaySpeed={5000}
        >
          {carouselImages.map((image, index) => (
            <div className="flex graphic-form  side-padding2 b-padding2 top-padding2" key={index}>
              <img src={image.src} alt={image.alt} style={{ width: '100%' }} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
