import React from "react";

import { MainEpicContainer } from "../components/MainEpicContainer";

const headerAdvanceSanctionScreening = {
  titleSize: "large",
  title: "Comprehensive Sanctions & PEP Screening for Risk Management",
  description: `Discover risks across millions of entities and individuals in over 200 countries and states. 
      Checklynx provides fast insights through a trusted and up-to-date database, consolidating 200+
      domestic and international sanction regimes. With coverage of companies, 
      individuals, vessels, and aircraft, it delivers industry-leading sanction compliance solutions for your business.`,
};


const headerCustomerOngoingMonitoring = {
  titleSize: "large",
  title: "Automated Ongoing Customer Monitoring for AML Compliance",
  description: `Automate the ongoing monitoring and continuous screening of customers to maintain constant compliance. 
  Keep track of compliance with automated reports that log all monitoring activities`,
};



const SolutionSection = () => {
  return (
    <div className=" main-section dir-col  bg-backroundColor">
      <div className="">
        <MainEpicContainer 
        header={headerAdvanceSanctionScreening} 
        button={{ label: "Learn more", url: "/solutions/aml-sanctions-screening/" }} 
        
        />
      </div>
      <div className="top-padding3">
        <MainEpicContainer header={headerCustomerOngoingMonitoring} 
         button={{ label: "Learn more", url: "/solutions/aml-customer-ongoing-monitoring/" }} 
         />
      </div>
    </div>
  );
};

export default SolutionSection;
