import React from "react";
import { Button } from "antd";
import con from "../config.json";

import customersView from "../images/customersView.webp";

const HeroSection = () => {
  return (
    <div className="main-section   ">
      <div className="section-hero-layout max-width14 ">
        <div className=" dir-col   max-width5" >
          <div className="hero-header ">
            <p className="hero-header-title">
              Sanctions and PEP Compliance Software
            </p>

            <h1 className="hero-header-title hero-header-title-overlay  hero-header-title-burn">
              Sanctions and PEP Compliance Software
            </h1>
          </div>
          <div style={{ minHeight: "1rem" }}></div>
          <div style={{ zIndex: 4 }}>
          <h2 className="hero-subheader slide-in-down slide-in-down3">
            Simplify AML Compliance with Automated Sanctions & PEP Screening
            Solutions
          </h2></div>

          <ButtonsRow></ButtonsRow>
        </div>

        <div className="container-container-graphic " style={{ zIndex: 2 }}>
          <picture >
            <source
              
              srcSet={customersView}
              media="(min-width: 800px)"
              type="image/jpeg"
            />
            <img
              src=""
              alt="Customer ongoing monitoring dashboard view showing compliance insights and monitoring in Checklynx platform"
              className="container-graphic max-width7"
              style={{
                position: "absolute",
                top: "150px", // Adjust as needed
                left: "1190px", // Adjust as needed
                zIndex: 2, // Ensures the image stays on top
              }}
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

export const ButtonsRow = () => {
  return (
    <div className="buttons-row slide-in-down slide-in-down2">
      <a
        href={con.urls.checklynx_app_sign_up}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          className="w240 extra-height1 text-button-big"
          type="primary"
          size="large"
        >
          Try 30 days Free
        </Button>
      </a>

      <a href={con.calendly.url} target="_blank" rel="noopener noreferrer">
        <Button
          className="w240 extra-height1 text-button-big bg-white"
          type="primary"
          ghost={true}
          size="large"
        >
          Contact an expert
        </Button>
      </a>
    </div>
  );
};
