//landingPage
import React from "react";
import { Helmet } from "react-helmet";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";
import vesselInfo from "../images/vesselInfo.webp";
import vesselTarget from "../images/vesselTarget.webp";
import vesselSearch from "../images/vesselSearch.webp";

import { HeaderComp } from "../components/HeaderComp";

import { GradientContainer } from "../components/Gradients";
import { MainEpicContainer } from "../components/MainEpicContainer";
import { FAQ } from "../components/Utils";

const header = {
    h1: true,
    title: "Screening a Vessel or Aircraft",
    description: `Shipping companies, port authorities, ,
     and commodity traders need to screen vessels for sanctions and AML compliance. 
     Checklynx offers a solution to ensure compliance and mitigate risk in these critical sectors.`,
  };
  const features = [
    {
      title: "Avoid the Risk of Engaging with Sanctioned Vessels",
      description: `Shipping companies and financial institutions risk heavy penalties for unknowingly dealing with sanctioned vessels. Checklynx helps by automatically screening vessels against global sanction lists, ensuring compliance and reducing legal exposure.`,
    },
    {
      title: "Eliminate the Problem of Outdated Vessel Data",
      description: `Companies often struggle with outdated vessel data, leading to missed compliance risks. Checklynx provides real-time screening and monitoring, keeping you informed of any changes in a vessel's status or sanctions.`,
    },
    {
      title: "Simplify Complex Regulatory Compliance",
      description: `Navigating international AML regulations can be complex and time-consuming. Checklynx simplifies compliance by providing a unified platform to screen vessels according to the latest global and regional regulations, streamlining your compliance workflow.`,
    },
    {
      title: "Find Vessel Information with Incomplete or Inaccurate Details",
      description: `Identifying vessels can be challenging with incomplete details like IMO, MMSI, or call sign. Checklynx's fuzzy search can find vessels even with partial data, returning structured information on the owner and associated entities, helping you make informed decisions.`,
    },
  ];
  





const faqData = [
  
    { 
        question: "Why is vessel and aircraft screening necessary for compliance?", 
        answer: `Vessel and aircraft screening is crucial to ensure compliance with international regulations, as these assets can be involved in illicit activities like smuggling or sanctions violations. Screening helps businesses avoid engaging with vessels or aircraft linked to sanctioned entities, reducing legal and financial risks.`,
      },
      { 
        question: "What data is required to screen a vessel or aircraft?", 
        answer: `Typically, vessels are identified using IMO, MMSI, or call sign, while aircraft are identified by registration number. Checklynx allows you to screen these assets using any of these identifiers, ensuring a comprehensive compliance check, even with partial information.`,
      },
  
 
];


const carouselImages = [
    { src: vesselSearch, alt: 'Vessel Search' },
    { src: vesselInfo, alt: 'Vessel Info' },
    { src: vesselTarget, alt: 'Vessel Target' }
  ];



const CustomerScreeningPage = () => {
  return (
    <AppLayoutLogin>
      <Helmet>
  <link
    rel="canonical"
    href="https://checklynx.com/solutions/aml-maritime-aviation-screening"
  />
  <title>
    AML Compliance in Maritime and Aviation: How to Screen Vessels | Checklynx
  </title>
  <meta
    name="description"
    content="Learn how to effectively screen vessels for Anti-Money Laundering (AML) compliance in the maritime and aviation sectors. Discover how Checklynx helps you ensure regulatory adherence and prevent illicit activities."
  />
</Helmet>
      <GradientContainer>
        <HeaderComp />
      </GradientContainer>
      <div className="main-section dir-col max-width16">
        <MainEpicContainer
          header={header}
          features={features}
          carouselImages={carouselImages}
        />

       
       
      </div>
      <div className="main-section dir-col max-width12">
        <FAQ faqData={faqData}></FAQ>
        </div>

      <Footer showContactCard={true} />
    </AppLayoutLogin>
  );
};

export default CustomerScreeningPage;
